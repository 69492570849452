import Header from './components/Header';
import Media from './components/Media';
import Contact from './components/Contact';
import Information from './components/Information';
import Footer from './components/Footer';
import Copyright from '../components/Copyright/Copyright.vue';
import SupportBlock from '../components/SupportBlock/SupportBlock.vue';

export default {
  name: 'Homepage',
  components: {
    Header,
    Media,
    Contact,
    Information,
    Footer,
    Copyright,
    SupportBlock
  },
  data() {
    return {
      listOfPage: null
    };
  }
};
