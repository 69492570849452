<template>
  <div class="media-block">
    <b-container>
      <b-row>
        <b-col>
          <div class="media-content">
            <div class="media-cover">
              <iframe
                src="https://www.youtube.com/embed/5Gl_vck0SDY"
                title="YouTube video player"
                frameborder="0"
                height="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="media-footer"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BContainer, BCol, BRow } from 'bootstrap-vue';

export default {
  name: 'Media',
  components: {
    BContainer,
    BCol,
    BRow
  }
};
</script>
<style scoped>
.media-block {
  margin-top: 60px;
}
.media-content {
  position: relative;
  height: 100%;
  width: 100%;
}
.media-footer {
  height: 12vw;
  width: 100%;
  background-color: #29aae2;
  position: absolute;
  bottom: -60px;
  z-index: 99;
  opacity: 0.5;
}
.media-cover {
  height: 95%;
  z-index: 100;
  position: relative;
}
.media-cover iframe {
  width: 90%;
  height: 35vw;
  margin: auto;
  border: 5px solid #fff;
}
@media only screen and (max-width: 1024px) {
  .media-footer {
    height: 20vw;
  }
  .media-cover iframe {
    height: 40vw;
  }
}
@media only screen and (max-width: 640px) {
  .container {
    padding: 0;
  }
  .media-footer {
    bottom: -20px;
  }
  .media-cover iframe {
    height: 40vw;
  }
}
@media only screen and (max-width: 375px) {
  .media-cover iframe {
    height: 45vw;
  }
}
</style>
