<template>
  <div class="header" :class="{ 'full-height': isHorizontalScreen($store) }">
    <b-container class="my-auto">
      <b-row class="custom-width">
        <b-col v-for="page in listOfPage" :key="page.id">
          <a href="#" v-scroll-to="`#${page.id}-homepage`" class="menu">{{
            page.name
          }}</a>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'home' }">
            <img :src="logo" :alt="appName" class="logo" />
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="custom" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="intro">
            <span>Nền tảng thiết kế ấn phẩm in trực tuyến</span><br />
            <span>Hãy tạo ra một bản thiết kế cho riêng mình ngay</span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link
            :to="{ name: 'category', params: { category: mainCategorySlug } }"
            @click.native="onTrack()"
            class="main-design-link"
          >
            <b-button class="start">
              <h4>Bắt đầu thiết kế ngay</h4>
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { listOfPage, designIcons } from '@/utils/mediaData';
import { onTrackClick, isHorizontalScreen } from '@/utils/commonUtils';
import {
  BContainer,
  BCol,
  BRow,
  BTooltip,
  BLink,
  BButton
} from 'bootstrap-vue';

export default {
  name: 'Header',
  components: {
    BContainer,
    BCol,
    BRow,
    BTooltip,
    BLink,
    BButton
  },
  data() {
    return {
      listOfPage,
      logo: designIcons.logo,
      appName: process.env.VUE_APP_TITLE,
      isHorizontalScreen,
      mainCategorySlug: null
    };
  },
  methods: {
    onTrack() {
      onTrackClick('#id_start', this.$gtag);
    },
    initData() {
      const store = this.$store;
      const { productCategoryList } = store.state.app;

      productCategoryList.forEach(element => {
        if (element.selected === 1) {
          this.mainCategorySlug = element.slug;
          return true;
        }
      });
    }
  },
  created() {
    this.initData();
  }
};
</script>
<style scoped>
.header {
  background-image: url(https://res.cloudinary.com/nitpen/image/upload/v1617723485/assets/theme/img/home-page/head_img-02_a0vge5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 800px;
}

.header.full-height {
  height: 100vh !important;
}

.custom-width {
  max-width: 600px;
  margin: 0 auto 30px;
}

.menu {
  font-size: 28px;
  color: #333;
}

.menu:hover {
  color: #000;
}

.menu::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgb(119, 177, 231);
  transition: width 0.3s;
}

.menu:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.logo {
  width: 350px;
}

.intro {
  margin-bottom: 45px;
}

.custom {
  width: 30%;
  background-color: #333;
  margin: 15px auto;
}

.start {
  position: relative;
  background-color: #29aae2;
  border-color: #29aae2;
  width: 368px;
  height: 58px;
  border-radius: 34px;
  box-shadow: 0 0 0 0 rgba(90, 153, 21, 0.5);
  transition: transform 0.8s;
  animation: pulse 2s infinite, shake 6s infinite;
}

.start:hover {
  background-color: rgb(83, 167, 235);
  animation: none;
  transform: scale(1.05);
}

.start h4 {
  color: #fff;
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 50px rgba(90, 153, 21, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@keyframes shake {
  from,
  16%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  1.6%,
  4.8%,
  8%,
  11.2%,
  14.4% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  3.2%,
  6.4%,
  9.6%,
  12.8% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .header {
    height: 600px;
  }

  .container {
    padding: 0;
  }

  .menu {
    font-size: 18px;
  }

  .logo {
    width: 250px;
  }

  .start {
    width: 280px;
  }

  .start h4 {
    font-size: 20px;
  }
}

@media only screen and (max-height: 420px) {
  .container {
    padding: 0;
  }

  .menu {
    font-size: 18px;
  }

  .intro {
    margin-bottom: 20px;
  }

  .custom-width {
    margin-bottom: 10px;
  }

  .logo {
    width: 150px;
  }

  .start {
    width: 280px;
  }

  .start h4 {
    font-size: 20px;
  }
}
</style>
