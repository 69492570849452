<template>
  <div class="footer">
    <div class="container">
      <div class="row row-0-0">
        <div class="col-sm">
          <div class="row">
            <div class="col-sm col-0-0"><h4>Ấn phẩm</h4></div>
          </div>
          <div class="row">
            <div class="col-sm"><hr class="hr-0" /></div>
          </div>
          <div
            class="row"
            v-for="template in $store.state.app.productList"
            :key="template.id"
          >
            <div class="col-sm col-0-1">
              <div class="row row-stick">
                <div class="col-sm">
                  <img
                    class="img-stick"
                    src="https://res.cloudinary.com/nitpen/image/upload/v1617723485/assets/theme/img/home-page/stick-02_okml7h.png"
                    alt=""
                  />
                </div>
                <div class="col-sm col-0-2 col-0-2">
                  <h6>{{ template.productName }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="row">
            <div class="col-sm col-0-0"><h4>Bài viết</h4></div>
          </div>
          <div class="row">
            <div class="col-sm"><hr class="hr-0" /></div>
          </div>
          <div class="row row-0-1">
            <div class="col-sm">
              <div class="row" v-for="post in listOfPost" :key="post.id">
                <div class="col-sm"><hr class="hr-1" /></div>
                <div class="col-sm">
                  <p class="p-1">
                    {{ post.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="row">
            <div class="col-sm">
              <img
                src="https://res.cloudinary.com/nitpen/image/upload/v1617723489/assets/theme/img/home-page/website-02_ycm102.png"
                alt=""
                id="img-0-1"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm text-3-2">
              <!-- <p class="p-2">
                Lorem ipsum dolor sit amet, consec-tetuer adipiscing elit, sed
                diam nonum-my ni cidunt ut
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
.footer {
  padding: 50px;
  background-color: #f4f4f5;
  background-size: cover;
  position: relative;
}
.row h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}
.col-0-2 h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 20px;
  opacity: 0.8;
}

hr {
  border-style: solid;
  border-width: 1px;
  border-color: #29aae2;
  width: 380px;
}
.hr-1 {
  height: 65px;
  border-width: 3px;
  width: 120px;
  margin-left: 1px;
  margin-top: -1px;
  padding: 7px;
}

.hr-0 {
  width: 370px;
  background-color: #29aae2;
}
.col-0-1 {
  display: flex;
}
.col-0-2 {
  display: contents;
}
#img-0-1 {
  position: relative;
  left: 36px;
  margin-top: 30px;
  margin-left: 70px;
  padding: 21px;
}
.p-1 {
  text-align: justify;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  margin-left: -26px;
}
.p-2 {
  display: inline-block;
  margin-left: 71px;
  margin-top: -17px;
  text-align: center;
  width: inherit;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.col-0-0 {
  text-align: left;
}
.img-stick {
  vertical-align: super;

  width: 16px;
}
</style>

<script>
/**
 * TODO
 * REMOVE ALL mocked data
 */
import { listOfPost } from '@/utils/mediaData.js';
export default {
  name: 'Footer',
  data() {
    return {
      listOfPost
    };
  }
};
</script>
