<template>
  <div class="contact-form" id="contact-homepage">
    <b-container>
      <b-row>
        <b-col>
          <h3>GỬI PHẢN HỒI CHO CHÚNG TÔI</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form @submit="onSendFeedback($event)">
            <b-row>
              <b-col :md="isMobile($store) ? 12 : 6">
                <b-form-group>
                  <b-form-input
                    v-model="contactForm.name"
                    :state="validateState('name')"
                    aria-describedby="input-name"
                    placeholder="Họ và tên"
                  />
                  <b-form-invalid-feedback
                    id="input-name"
                    class="text-left"
                    v-for="(error, index) in errors.name"
                    :key="index"
                    >{{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col :md="isMobile($store) ? 12 : 6">
                <b-form-group>
                  <b-form-input
                    v-model="contactForm.email"
                    :state="validateState('email')"
                    aria-describedby="input-email"
                    type="email"
                    placeholder="Email"
                  />
                  <b-form-invalid-feedback
                    id="input-email"
                    class="text-left"
                    v-for="(error, index) in errors.email"
                    :key="index"
                    >{{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col :md="isMobile($store) ? 12 : 6">
                <b-form-group>
                  <b-form-input
                    v-model="contactForm.phone"
                    :state="validateState('phone')"
                    aria-describedby="input-phone"
                    class="form-control"
                    placeholder="Số điện thoại"
                  />
                  <b-form-invalid-feedback
                    id="input-phone"
                    class="text-left"
                    v-for="(error, index) in errors.phone"
                    :key="index"
                    >{{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col :md="isMobile($store) ? 12 : 6">
                <b-form-group>
                  <b-form-input
                    v-model="contactForm.age"
                    :state="validateState('age')"
                    aria-describedby="input-age"
                    type="datetime"
                    class="form-control"
                    placeholder="Tuổi"
                  />
                  <b-form-invalid-feedback
                    id="input-age"
                    class="text-left"
                    v-for="(error, index) in errors.age"
                    :key="index"
                    >{{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="form-group">
                  <b-form-group>
                    <b-form-textarea
                      v-model="contactForm.note"
                      :state="validateState('note')"
                      aria-describedby="input-note"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Nội dung phản hồi"
                    ></b-form-textarea>
                    <b-form-invalid-feedback
                      id="input-note"
                      class="text-left"
                      v-for="(error, index) in errors.note"
                      :key="index"
                      >{{ error }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-left">
                <b-button type="submit" class="btn-submit">
                  GỬI
                </b-button>
              </b-col>
              <b-col>
                <div class="img-social clearfix">
                  <div class="float-right ml-2">
                    <a :href="FACEBOOK" title="Facebook" target="_blank">
                      <img
                        src="https://res.cloudinary.com/nitpen/image/upload/v1617723485/assets/theme/img/home-page/icon_social-01_oucir2.png"
                        alt="Facebook"
                      />
                    </a>
                  </div>
                  <div class="float-right">
                    <a :href="YOUTUBE" title="Youtube" target="_blank">
                      <img
                        src="https://res.cloudinary.com/nitpen/image/upload/v1617723485/assets/theme/img/home-page/icon_social-03_o7steh.png"
                        alt="Youtube"
                      />
                    </a>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.contact-form {
  margin-top: 110px;
}
.btn-submit {
  font-size: 22px;
  width: 144px;
  border-radius: 10px;
  background-color: #29aae2;
  border-color: #29aae2;
}
.btn-submit:hover {
  background-color: #0062cc;
}
.img-social img {
  width: 35px;
}

@media only screen and (max-width: 640px) {
  .contact-form {
    margin-top: 40px;
  }
}
</style>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BContainer,
  BRow,
  BCol,
  BToast
} from 'bootstrap-vue';
import { RequestFactory } from '@Request/RequestFactory';
import { isMobile } from '@/utils/commonUtils';
import { FACEBOOK, YOUTUBE } from '@/utils/constants';

const ContactRequest = RequestFactory.get('contact');

export default {
  name: 'Contact',
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BContainer,
    BRow,
    BCol,
    BToast
  },
  data() {
    return {
      contactForm: {
        name: '',
        phone: '',
        age: '',
        email: '',
        note: ''
      },
      errors: {},
      isMobile,
      FACEBOOK,
      YOUTUBE
    };
  },
  methods: {
    validateState(field) {
      return this.errors[field] ? false : null;
    },
    onSendFeedback(e) {
      e.preventDefault();
      const _this = this;
      const loader = _this.$loading.show();

      ContactRequest.sendContact(_this.contactForm)
        .then(({ data }) => {
          const { message } = data;

          loader.hide();

          _this.$toastr.s(message);
        })
        .catch(({ response }) => {
          const { data } = response;
          const { message, errors } = data;
          _this.errors = errors;

          loader.hide();
          _this.$toastr.e(message);
        });
    }
  }
};
</script>
